import React, { useEffect, useState } from 'react';
import { useTranslationContext } from '../../../provider/TranslationProvider';
import { ChevronRightIconV2, XMarkIconV2 } from '../icons';
import { FadeUp } from '../../common/Animations';
import { ListingService } from '../../../helpers/services';
import IdentifierService from '../../../helpers/services/identifier.service';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../lib/hooks';
import {
  SelfListingData,
  selfListingInitialState,
  setOpenSelfListingFlow,
  updateContinueListingData,
  updateSelfListingFlow,
  updateSelfListingStep,
} from '../../../lib/self-listing/selfListing.slice';
import { SelfListingUtils, SessionUtils } from '../../../helpers/utilities';
import { useRouter } from 'next/router';
import { SelfListingConstants } from '../../../types/i18n.labels';
import { otherVariantValue } from '../../../types/constants';

const ContinueListingToast = () => {
  const { LabelConstants } = useTranslationContext();
  const continueListingData = useAppSelector(
    (state) => state.selfListing.continueListingData
  );
  const selfListingData = useAppSelector(
    (state) => state.selfListing.data
  ) as SelfListingData;
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    async function getLatestStep() {
      if (SessionUtils.isValidSession()) {
        const gaId = (await IdentifierService.getGAId()) || '';
        try {
          const resp = await ListingService.getLatestSLStep(gaId);
          if (resp.success) {
            if (resp.data !== null) {
              const listingDataResp = await ListingService.getSavedSLDataById(
                resp.data.VehicleListingId
              );
              if (listingDataResp.success) {
                const finalDataToDispatch: SelfListingData = {
                  ...selfListingInitialState.data,
                  brand: {
                    brandId: listingDataResp.data.MakeCode,
                    brandName: listingDataResp.data.Make,
                  },
                  manufacture_year: {
                    year: parseInt(listingDataResp.data.Year),
                    yearCode: listingDataResp.data.ModelYearCode,
                  },
                  model: {
                    modelId: listingDataResp.data.ModelCode || 0,
                    modelName: listingDataResp.data.Model || '',
                    modelYear: parseInt(listingDataResp.data.Year),
                  },
                  variant: {
                    variantId: listingDataResp.data.SpecCode || 0,
                    variantName: listingDataResp.data.Trim || '',
                  },
                  city: {
                    cityId: listingDataResp.data.CityId || 0,
                    cityName: listingDataResp.data.City || '',
                  },
                  kms_driven: listingDataResp.data.OdometerReading || 0,
                  expected_price: listingDataResp.data.AskingPrice || 0,
                  vehicle_listing_id: resp.data.VehicleListingId,
                };
                dispatch(updateSelfListingFlow(finalDataToDispatch));
                dispatch(
                  updateContinueListingData({
                    isVisible: true,
                    isFetched: true,
                    data: {
                      CurrentStep: resp.data
                        .CurrentStep as SelfListingConstants,
                      VehicleListingId: resp.data.VehicleListingId,
                    },
                  })
                );
              }
            } else {
              dispatch(
                updateContinueListingData({ isVisible: false, isFetched: true })
              );
            }
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        const localStep = SelfListingUtils.getCurrentStepFromCookies();
        const localData = SelfListingUtils.getSelfListingDataFromCookies();
        if (!localStep || !localData) {
          return;
        }
        if (localStep && localData) {
          dispatch(
            updateContinueListingData({
              isVisible: true,
              isFetched: true,
              data: {
                VehicleListingId: null,
                CurrentStep: localStep,
              },
            })
          );
          dispatch(
            updateSelfListingFlow({
              ...selfListingInitialState.data,
              ...localData,
            })
          );
        } else {
          dispatch(
            updateContinueListingData({ isVisible: false, isFetched: true })
          );
        }
      }
    }
    getLatestStep();
  }, []);

  const handleClose = () => {
    dispatch(updateContinueListingData({ isVisible: false }));
  };

  const handleContinueListing = () => {
    if (continueListingData.isFetched && continueListingData.data) {
      const nextStep = SelfListingUtils.getNextStepFromCurrentStep(
        continueListingData.data.CurrentStep
      );
      if (!nextStep) {
        return;
      }
      dispatch(updateSelfListingStep(nextStep));
      dispatch(setOpenSelfListingFlow(true));
      dispatch(
        updateSelfListingFlow({
          vehicle_listing_id: continueListingData.data.VehicleListingId,
        })
      );
      dispatch(updateContinueListingData({ isVisible: false }));
      router.push('sell-my-car');
    }
  };
  const getVehicleName = (
    step: SelfListingConstants | undefined,
    data: SelfListingData
  ) => {
    if (!step) {
      return '';
    }
    console.log({ step, data });
    switch (step) {
      case SelfListingConstants.SelfListingSelectBrand:
        return `${data.brand.brandName}`;

      case SelfListingConstants.SelfListingSelectManufactureYear:
        return `${data.manufacture_year.year} ${data.brand.brandName}`;

      case SelfListingConstants.SelfListingSelectModel:
        return `${data.manufacture_year.year} ${data.brand.brandName} ${data.model.modelName}`;

      case SelfListingConstants.SelfListingSelectVariant:
        return `${data.manufacture_year.year} ${data.brand.brandName} ${
          data.model.modelName
        } ${
          data.variant.variantId === otherVariantValue
            ? ''
            : data.variant.variantName
        }`;

      default:
        if (
          data.brand.brandId &&
          data.manufacture_year.yearCode &&
          data.model.modelId
        ) {
          return `${data.manufacture_year.year} ${data.brand.brandName} ${
            data.model.modelName
          } ${
            data.variant.variantId === otherVariantValue
              ? ''
              : data.variant.variantName
          }`;
        } else {
          return '';
        }
    }
  };

  return (
    <>
      {continueListingData.isVisible ? (
        <FadeUp className="sticky flex flex-col gap-[4px] bottom-0 z-[98] left-0 ltr:sm:left-1/2 rtl:sm:right-1/2 ltr:sm:!-translate-x-1/2 rtl:sm:!translate-x-1/2 w-full sm:w-[561px] ">
          <button
            onClick={handleClose}
            className="bg-black/70 sm:hidden px-[23px] py-[9px] w-fit rounded-full text-white text-11 font-semibold flex items-center gap-[5px] self-end ltr:mr-[24px] rtl:ml-[24px] backdrop-blur-[12px]"
          >
            <XMarkIconV2 stroke="white" />
            {LabelConstants.HIDE}
          </button>
          <div
            onClick={() => {
              if (window.innerWidth < 640) {
                handleContinueListing();
              }
            }}
            className="px-[24px] relative sm:px-[32px] pt-[14px] sm:pt-[20px] pb-[19px] sm:pb-[20px] bg-black/70 sm:rounded-t-[24px] backdrop-blur-[12px] sm:backdrop-blur-[24px] flex items-center justify-between"
          >
            <button
              onClick={handleClose}
              className="absolute hidden sm:block top-[12px] ltr:right-[16px] rtl:left-[16px] text-13 font-semibold text-white underline "
            >
              {LabelConstants.HIDE}
            </button>
            <div className="flex flex-col max-w-[68%] ltr:sm:pr-[66px] rtl:sm:pl-[66px] overflow-hidden overflow-ellipsis gap-[2px]">
              <p className="text-18 sm:text-20 font-medium whitespace-nowrap text-white">
                {LabelConstants.FINISH_YOUR_LISTING}
              </p>
              <p className="text-11 sm:text-15 opacity-70 sm:opacity-100 whitespace-nowrap overflow-hidden overflow-ellipsis text-white">
                {getVehicleName(
                  continueListingData.data?.CurrentStep || undefined,
                  selfListingData
                )}
              </p>
            </div>
            <button
              onClick={handleContinueListing}
              className="w-[38px] ltr:sm:mr-[66px] rtl:sm:ml-[66px] sm:w-[48px] h-[38px] sm:h-[48px] border sm:border-2 flex items-center justify-center rounded-full border-white/30"
            >
              <ChevronRightIconV2 className="ltr:rotate-0 rtl:rotate-180 sm:w-[25.26px] sm:h-[24.26px]" />
            </button>
          </div>
        </FadeUp>
      ) : null}
    </>
  );
};

export default ContinueListingToast;
